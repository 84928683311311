// import changeBoxToolButtons from './changeBoxToolButtons';
// import changeColumnToolButtons from './changeColumnToolButtons';
// import changeElmToolButtons from './changeElementToolButtons';
// import changeRowToolButtons from './changeRowToolButtons';
// import changeSectionToolButtons from './changeSectionToolButtons';

// changeColumnToolButtons();
// changeRowToolButtons();
// changeBoxToolButtons();
// changeSectionToolButtons();
// changeElmToolButtons();

(window as any).applyEditorChanges = () => {
  // (window as any).changeAllBoxEdit?.();
  // (window as any).changeAllCellMore?.();
  // (window as any).changeElmOptions?.();
  // (window as any).changeAllRowMore?.();
  // (window as any).changeAllSectionEdit?.();
};

window.commUtilsTrigger = function () {
  if(window === parent) {
    return;
  }
  parent.commUtilsTrigger?.(...arguments);
};

document.addEventListener('selectionchange', (event) => {
  const selection = document.getSelection();
  const btnLink = parent.document.querySelector('.panel-text .btn-link') as
    | HTMLButtonElement
    | HTMLAnchorElement
    | null;
  if (selection?.focusOffset === selection?.anchorOffset) {
    if (btnLink) {
      btnLink.style.display = 'none';
    }
  } else {
    if (btnLink) {
      btnLink.style.display = 'initial';
    }
  }
});

document.addEventListener('click', (e) => {
  if (
    window.location.hostname.includes('learnyst.com') ||
    window.location.hostname.includes('localhost')
  ) {
    e.preventDefault();
    return;
  }
  if ((e.target as HTMLElement).tagName.toLowerCase() === 'a') {
    const href = (e.target as HTMLAnchorElement)?.getAttribute('href');
    if (!href?.includes('#cta-id=')) {
      return;
    }
    const id = href.split('#cta-id=')[1] || 0;
    const ctaWithId = document.querySelector(`lyst-cta-popup[cta-id="${id}"]`);
    if (ctaWithId) {
      ctaWithId.remove();
    }
    const cta = document.createElement('lyst-cta-popup');
    cta.setAttribute('cta-id', id.toString());
    cta.setAttribute('show', 'true');
    document.body.append(cta);
    e.preventDefault();
  }
});

const attachButtonCTAs = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isDesignerMode = !!queryParams.get('isDesignerMode');
  document.querySelector('embed-container')?.querySelectorAll('lyst-cta-embed-button').forEach(el => {
    el.remove();
  })
  document.querySelectorAll('a').forEach(el => {
    if(el.href.includes('#cta-button-id')) {
      const ctaId = el.href.split('=')[1];
      const lystCtaEmbedButton = document.createElement('lyst-cta-embed-button');
      lystCtaEmbedButton.setAttribute('external-button', 'true');
      lystCtaEmbedButton.setAttribute('cta-id', ctaId);
      if(!isDesignerMode) {
        setTimeout(() => {
          lystCtaEmbedButton.buttonElement = el;
        }, 200);
      }
      document.querySelector('embed-container')?.appendChild(lystCtaEmbedButton);
    }
  })
}

const addCTAManager = () => {
  const ctaManager = document.createElement('lyst-cta-manager');
  document.body.appendChild(ctaManager);
}

const interval = setInterval(() => {
  if(!window.schoolId) {
    return;
  }
  clearInterval(interval);
  attachButtonCTAs();
  addCTAManager();
}, 500);

if (!customElements.get('lyst-widget-wrapper')) {
  import('../lystCustomElements/lystWidgetWrapper');
}
